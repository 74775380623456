import React, { useState } from "react";
import "../menu/Questions.css";

export default function Answers({ text, img, hover, className, className1 }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <span onMouseEnter={handleHover} onMouseLeave={handleLeave}>
      {isHovered ? null : (
        <div className="figure">
          <img src={img} alt="figura" />
          <p className={className}>
            {text.text1} <br />
            {text.text2} <br />
            {text.text3}
          </p>
        </div>
      )}
      {isHovered && (
        <div className="figure">
          <img src={hover} alt="figure" />
          <p className={className1}>
            {text.text4} <br />
            {text.text5} <br />
            {text.text6} <br />
            {text.text7}
          </p>
        </div>
      )}
    </span>
  );
}
