import "./App.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Change import to useNavigate
import Gradient from "./Animations/Gradient";
import logo from "./img/logo-anomala.svg";
import logoM from "./img/logo-m.svg";
import { motion } from "framer-motion";

export default function App() {
  const [showText, setShowText] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const navigate = useNavigate(); // Change from useHistory to useNavigate

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setShowText(true);
    }, 2000);

    const hideTimeout = setTimeout(() => {
      setShowText(false);
      setShowImage(true);
    }, 4000);

    const navigateTimeout = setTimeout(() => {
      navigate("/Homepage"); // Use navigate function instead of history.push
    }, 7000);

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
      clearTimeout(navigateTimeout);
    };
  }, [navigate]);

  return (
    <div className="App">
      <Gradient className="top-right" />
      <Gradient className="bottom-left" />
      {showText && (
        <motion.div
          animate={{ scale: 1.2 }}
          transition={{ type: "spring", stiffness: 100, duration: 2 }}
          exit={{ scale: 0 }}
        >
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </motion.div>
      )}
      {showImage && (
        <motion.div initial={{ scale: 1.2 }}>
          <div className="logo">
            <img src={logoM} alt="logo" />
          </div>
        </motion.div>
      )}
    </div>
  );
}
