import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../Footer";
import Header from "../Header";
import "../menu/Contact.css";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

export default function Contact() {
  const name = useRef(null);
  const email = useRef(null);
  const message = useRef(null);
  const sendContact = (e) => {
    console.log(message.current);
    console.log(email.current);
    console.log(name.current);
    let inter = message.current.value;
    let mail = email.current.value;
    let nom = name.current.value;
    e.preventDefault();
    axios
      .post("https://anomala.eram.cat/api/contact/register", {
        name: nom,
        email: mail,
        message: inter,
      })
      .then((res) => {
        console.log(res);
        NotificationManager.success(
          "Enviat amb èxit!",

          3000
        );
      })
      .catch((error) => {
        console.error("Error en l'enviament del correu:", error);

        NotificationManager.error(
          "Error en l'enviament del correu o ja està registrat.",
          3000
        );
      });
  };
  return (
    <div className="contact-body">
      <Header title="Contacte" />
      <h1 id="contact-title" className="mt-5">
        Contacta amb nosaltres
      </h1>
      <div className="container" id="container">
        <form>
          <h4>Nom</h4>
          <input
            type="text"
            placeholder="Escriu el teu nom i cognoms aquí"
            required
            ref={name}
          />
          <h4 className="mt-4">Email</h4>
          <input
            type="email"
            placeholder="Escriu el teu email aquí"
            pattern=".+@example\.com"
            minLength={6}
            ref={email}
            required
          />
          <h4 className="mt-4">Missatge</h4>
          <textarea
            placeholder={`Escriu aquí el teu missatge i comparteix els teus dubtes i les teves opinions sobre el \n projecte amb nosaltres!`}
            required
            ref={message}
            minLength={12}
            id="message"
          />
          <br />
          <button className="mt-5 mb-5" onClick={sendContact} id="contact-send">
            Enviar
          </button>
        </form>
      </div>
      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="place col-md-6 col-sm-12">
            <h5>Visita l’experiència</h5>
            <p>
              Del 18 al 21 de gener de 2024 <br />
              ERAM. Carrer de Sant Antoni, 1, Edifici Kropotkin, 1ª <br />
              Planta, 17190 Salt, Girona
            </p>
          </div>
          <div className="place col-md-6 col-sm-12">
            <h5>Visita la nostra instal·lació</h5>
            <p>
              Del 22 de maig al 6 de juny de 2023 <br /> Plaça de l'Hospital 6{" "}
              <br /> 17002 Girona
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
