import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../Footer";
import Header from "../Header";
import "../menu/Questions.css";
import Answers from "./Answers";
import Figure1 from "../img/figure1.svg";
import Figure1Hover from "../img/figure1-hover.svg";
import Figure2 from "../img/figure2.svg";
import Figure2Hover from "../img/figure2-hover.svg";
import Figure3 from "../img/figure3.svg";
import Figure3Hover from "../img/figure3-hover.svg";
import Figure4 from "../img/figure4.svg";
import Figure4Hover from "../img/figure4-hover.svg";
import Figure5 from "../img/figure5.svg";
import Figure5Hover from "../img/figure5-hover.svg";
import Figure6 from "../img/figure6.svg";
import Figure6Hover from "../img/figure6-hover.svg";

export default function Questions() {
  return (
    <div className="body">
      <Header title="FAQs" />
      <div className="fluid-container mt-5" id="questions">
        <div className="row">
          <span className="figure col-lg-4 col-sm-12">
            <Answers
              className={"question-text"}
              className1={"ms-3 answer-text"}
              text={{
                text1: "Quant de temps es",
                text2: "necessita per realitzar",
                text3: "l’experiència?",
                text4: "Es recomanen",
                text5: "aproximadament 2 ",
                text6: "hores per a realitzar",
                text7: "l’experiència.",
              }}
              img={Figure1}
              hover={Figure1Hover}
            />
          </span>
          <span className="figure col-lg-4 col-sm-12 down">
            <Answers
              className={"question-text"}
              className1={"answer-text"}
              text={{
                text1: "Amb quina antelació ",
                text2: "haig d'arribar abans de ",
                text3: "la visita?",
                text4: "Es recomana una",
                text5: "antelació de 30 minuts ",
                text6: "abans de l'hora de la visita",
                text7: "per a agilitzar l'entrada.",
              }}
              img={Figure2}
              hover={Figure2Hover}
            />
          </span>
          <span className="figure col-lg-4 col-sm-12">
            <Answers
              className={"question-text"}
              className1={"ms-3  answer-text"}
              text={{
                text1: "És segura l’experiència ",
                text2: "per a una persona amb",
                text3: "epilèpsia?",
                text4: "No es recomana ",
                text5: "l'accés al centre a ",
                text6: "persones amb",
                text7: "epilèpsia ",
              }}
              img={Figure3}
              hover={Figure3Hover}
            />
          </span>
        </div>
        <div className="row mt-5 mb-5">
          <span className="figure col-lg-4 col-sm-12 ">
            <Answers
              className="mt-4 ms-4 question-text"
              className1={"mt-5 ms-2 answer-text"}
              text={{
                text1: "Puc deixar algun ",
                text2: "objecte en taquilla?",
                text3: "",
                text4: "No, no tenim servei de  ",
                text5: "guarda-roba.",
                text6: "",
                text7: "",
              }}
              img={Figure4}
              hover={Figure4Hover}
            />
          </span>
          <span className="figure col-lg-4 col-sm-12">
            <Answers
              className="mt-3 question-text"
              className1={"mt-3 answer-text"}
              text={{
                text1: "Es poden prendre fotos",
                text2: "dins del recinte?",
                text3: "",
                text4: "Es poden realitzar fotos ",
                text5: "en tot el recinte, sempre ",
                text6: "que es facin sense flaix.",
                text7: "",
              }}
              img={Figure5}
              hover={Figure5Hover}
            />
          </span>
          <span className="figure col-lg-4 col-sm-12 mb-5">
            <Answers
              className="mt-3 question-text"
              className1={"mt-5 answer-text"}
              text={{
                text1: "Poden els menors d'edat ",
                text2: "entrar al centre sense ",
                text3: "acompanyament?",
                text4: "Els menors de 18 anys no ",
                text5: "poden vindre a ",
                text6: "l’experiència ",
                text7: "",
              }}
              img={Figure6}
              hover={Figure6Hover}
            />
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
}
