import React, { useState } from "react";
import { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./menu/Homepage.css";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

import instagram from "./img/instagram.png";
import youtube from "./img/youtube.png";
import youtubecolor from "./img/youtube-hover.png";
import GradientFooter from "./Animations/FooterGradient";
import instagramcolor from "./img/icons8-instagram-circle-96-hover.png";

export default function Footer() {
  const handleHover2 = () => {
    setIsHovered2(!isHovered2);
  };

  const [image2, setImage2] = useState(youtube);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [image, setImage] = useState(instagram);

  const interested = useRef(null);
  const sendInterested = (e) => {
    console.log(interested.current);
    let inter = interested.current.value;
    e.preventDefault();
    axios
      .post("https://anomala.eram.cat/api/interested/register", {
        email: inter,
      })
      .then((res) => {
        console.log(res);
        NotificationManager.success(
          "Enviat amb èxit!",

          3000
        );
      })
      .catch((error) => {
        console.error("Error en l'enviament del correu:", error);

        NotificationManager.error(
          "Error en l'enviament del correu o ja està registrat.",
          3000
        );
      });
  };

  return (
    <footer>
      <div className="footer-wave">
        <GradientFooter className="footer-wave " />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12 d-flex flex-column mb-3">
            <h4>Contacte</h4>
            <a
              href="mailto:anomala.experience@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <p className="mb-2 mt-1">
                anomala.experience@gmail.com <br />
              </p>
            </a>
            <a href="tel:673435341" target="_blank" rel="noreferrer">
              <p> +34 673 43 53 41 </p>
            </a>
          </div>
          <div className="col-lg-4 col-sm-12 d-flex flex-column mb-3">
            <h4>Xarxes</h4>
            <div className="d-flex flex-row mb-3 mt-1 ">
              <a
                href="https://www.instagram.com/anomala.experience?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                rel="noreferrer"
                className="col-6 me-1"
              >
                <img
                  src={isHovered ? instagramcolor : instagram}
                  alt="instagram"
                  onMouseEnter={handleHover}
                  onMouseLeave={handleHover}
                />
              </a>
              <a href="//" className="col-6">
                <img
                  src={isHovered2 ? youtubecolor : youtube}
                  alt="instagram"
                  onMouseEnter={handleHover2}
                  onMouseLeave={handleHover2}
                />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 d-flex flex-column mb-3">
            <h4>Newsletter</h4>
            <p className="mb-2">
              Subscriu-te si vols rebre informació del projecte.
            </p>
            <form>
              <input
                type="text"
                className="form-text"
                placeholder="Email"
                ref={interested}
                name="interested"
                pattern=".+@example\.com"
              />{" "}
              <br />
              <input
                onClick={sendInterested}
                type="submit"
                placeholder="Enviar"
                className="form-button"
                minLength={6}
              />
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
}
