import React, { useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../Header";
import Footer from "../Footer";
import "../menu/Reservation.css";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

export default function Reservation() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const noms = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const people = useRef(null);
  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(name)) {
        return prevSelectedDays.filter((day) => day !== name);
      } else {
        return [...prevSelectedDays, name];
      }
    });
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const sendReservation = (e) => {
    // setSelectedDay(day);

    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    let mail = email.current.value;
    let nom = noms.current.value;
    let telef = phone.current.value;
    let gent = people.current.value;

    e.preventDefault();

    axios
      .post("https://anomala.eram.cat/api/reservation/register", {
        name: nom,
        email: mail,
        ubication: selectedOption,
        phone: telef,
        people: gent,
        day: selectedDays,
      })
      .then((res) => {
        console.log(res);
        NotificationManager.success(
          "Enviat amb èxit!",

          3000
        );
      })
      .catch((error) => {
        console.error("Error en l'enviament del correu:", error);

        NotificationManager.error(
          "Error en l'enviament del correu o ja està registrat.",
          3000
        );
      });
  };

  return (
    <div className="reservation-body">
      <Header title="Reserva" />
      <div className="container">
        <main>
          <h1>
            Reserva les teves entrades <br />i visita la instal·lació!
          </h1>
          <h4 className="mb-5"> Detalls del comprador</h4>
          <h5>Nom i Cognoms</h5>
          <form>
            <input
              type="text"
              placeholder="Escriu el teu nom i cognoms aquí"
              required
              ref={noms}
            />
          </form>
          <h5>Ubicació</h5>
          <select
            name="ubications"
            value={selectedOption}
            onChange={handleChange}
          >
            <option value="">--Sisplau tria una opció--</option>
            <option value="El Modern">El Modern</option>
            <option value="Eram,Salt">Eram, Salt</option>
          </select>

          <h5>Mòbil</h5>
          <form>
            <input
              type="tel"
              placeholder="Escriu teu mòbil aquí"
              minLength={9}
              maxLength={12}
              ref={phone}
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              required
            />
          </form>
          <h5>Email</h5>
          <form>
            <input
              type="email"
              placeholder="Escriu la teva adreça de correu electrònic aquí"
              pattern=".+@example\.com"
              minLength={6}
              required
              ref={email}
            />
          </form>
          <div className="numbers">
            <h5>Participants</h5>
            <form>
              <input
                type="number"
                placeholder="2"
                min={1}
                max={5}
                required
                ref={people}
              />
            </form>
          </div>
          <div className="horaris">
            <h4 className="mb-4 mt-5">Horari</h4>
          </div>
          {screenWidth > 720 ? (
            <div className="horaris">
              <div className="row">
                <p className="d-inline col-3">18/01/2024</p>
                <p className="d-inline col-3">19/01/2024</p>
                <p className="d-inline col-3">20/01/2024</p>
                <p className="d-inline col-3">21/01/2024</p>
              </div>
              <div className="row mb-3">
                <label className="cbox1">
                  <input
                    type="checkbox"
                    className="col-3 cbox2"
                    name="Dinar 18/01"
                    checked={selectedDays.includes("Dinar 18/01")}
                    onChange={handleCheckboxChange}
                  />
                  Dinar
                </label>
                <label className="cbox1">
                  <input
                    type="checkbox"
                    className="col-3 cbox2"
                    name="Dinar 19/01"
                    checked={selectedDays.includes("Dinar 19/01")}
                    onChange={handleCheckboxChange}
                  />
                  Dinar
                </label>
                <label className="cbox1">
                  <input
                    type="checkbox"
                    className="col-3 cbox2"
                    name="Dinar 20/01"
                    checked={selectedDays.includes("Dinar 20/01")}
                    onChange={handleCheckboxChange}
                  />
                  Dinar
                </label>
                <label className="cbox1">
                  <input
                    type="checkbox"
                    className="col-3 cbox2"
                    name="Dinar 21/01"
                    checked={selectedDays.includes("Dinar 21/01")}
                    onChange={handleCheckboxChange}
                  />
                  Dinar
                </label>
                <br />
              </div>
              <div className="row">
                <label className="cbox1">
                  <input
                    type="checkbox"
                    className="col-3 cbox2"
                    name="Sopar 18/01"
                    checked={selectedDays.includes("Sopar 18/01")}
                    onChange={handleCheckboxChange}
                  />
                  Sopar
                </label>
                <label className="cbox1">
                  <input
                    type="checkbox"
                    className="col-3 cbox2"
                    name="Sopar 19/01"
                    checked={selectedDays.includes("Sopar 19/01")}
                    onChange={handleCheckboxChange}
                  />
                  Sopar
                </label>
                <label className="cbox1">
                  <input
                    type="checkbox"
                    className="col-3 cbox2"
                    name="Sopar 20/01"
                    checked={selectedDays.includes("Sopar 20/01")}
                    onChange={handleCheckboxChange}
                  />
                  Sopar
                </label>
                <label className="cbox1">
                  <input
                    type="checkbox"
                    className="col-3 cbox2"
                    name="Sopar 21/01"
                    checked={selectedDays.includes("Sopar 21/01")}
                    onChange={handleCheckboxChange}
                  />
                  Sopar
                </label>
              </div>
            </div>
          ) : (
            <div className="container horaris">
              <div className="row">
                <div className="dijous col-6">
                  <p className="d-inline ">18/01/2024</p>
                  <label className="cbox1">
                    <input
                      type="checkbox"
                      className="col-3 cbox2"
                      name="Dinar 18/01"
                      checked={selectedDays.includes("Dinar 18/01")}
                      onChange={handleCheckboxChange}
                    />
                    Dinar
                  </label>
                  <label className="cbox1">
                    <input
                      type="checkbox"
                      className="col-3 cbox2"
                      name="Sopar 18/01"
                      checked={selectedDays.includes("Sopar 18/01")}
                      onChange={handleCheckboxChange}
                    />
                    Sopar
                  </label>
                </div>
                <div className="dijous col-6">
                  <p className="d-inline col-6">19/01/2024</p>
                  <label className="cbox1">
                    <input
                      type="checkbox"
                      className="col-3 cbox2"
                      name="Dinar 19/01"
                      checked={selectedDays.includes("Dinar 19/01")}
                      onChange={handleCheckboxChange}
                    />
                    Dinar
                  </label>
                  <label className="cbox1">
                    <input
                      type="checkbox"
                      className="col-3 cbox2"
                      name="Sopar 19/01"
                      checked={selectedDays.includes("Sopar 19/01")}
                      onChange={handleCheckboxChange}
                    />
                    Sopar
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="dijous col-6">
                  <p className="d-inline ">20/01/2024</p>
                  <label className="cbox1">
                    <input
                      type="checkbox"
                      className="col-3 cbox2"
                      name="Dinar 20/01"
                      checked={selectedDays.includes("Dinar 20/01")}
                      onChange={handleCheckboxChange}
                    />
                    Dinar
                  </label>
                  <label className="cbox1">
                    <input
                      type="checkbox"
                      className="col-3 cbox2"
                      name="Sopar 20/01"
                      checked={selectedDays.includes("Sopar 20/01")}
                      onChange={handleCheckboxChange}
                    />
                    Sopar
                  </label>
                </div>
                <div className="dijous col-6">
                  <p className="d-inline col-6">21/01/2024</p>
                  <label className="cbox1">
                    <input
                      type="checkbox"
                      className="col-3 cbox2"
                      name="Dinar 21/01"
                      checked={selectedDays.includes("Dinar 21/01")}
                      onChange={handleCheckboxChange}
                    />
                    Dinar
                  </label>
                  <label className="cbox1">
                    <input
                      type="checkbox"
                      className="col-3 cbox2"
                      name="Sopar 21/01"
                      checked={selectedDays.includes("Sopar 21/01")}
                      onChange={handleCheckboxChange}
                    />
                    Sopar
                  </label>
                </div>
              </div>
            </div>
          )}

          <button className="send mb-5 mt-4" onClick={sendReservation}>
            Enviar
          </button>
        </main>
      </div>
      <Footer />
    </div>
  );
}
