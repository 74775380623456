import React from "react";
// import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../Header";
import "../menu/Homepage.css";
import Footer from "../Footer";
// import Reservation from "./Reservation";

import Gradient from "../Animations/HomepageGradient";
import ReactPlayer from "react-player";

export default function Homepage() {
  return (
    <div id="homepage-body">
      <Header title="Homepage" />
      <main>
        <div className="title">
          <Gradient className="middle-up " />
          <Gradient className="middle-down" />
          <h1 id="homepage-title">
            Viu una <span className="fw-bolder">experiència gastronòmica </span>
            <br />
            interactiva i immersiva
          </h1>
        </div>
        <div className="homepage-video">
          <ReactPlayer
            url="https://youtu.be/R-dAiAwrG_Y"
            playing="true"
            loop="true"
            controls="true"
            width={"1000px"}
            height={"563px"}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}
