import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../Footer";
import Header from "../Header";
import "../menu/Project.css";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

import ERAM from "../img/ERAM.png";
import Modern from "../img/Modern.png";

export default function Project() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="project-body">
      <Header title="Projecte" />
      <div className="container-fluid">
        <div className="row project">
          {screenWidth < 720 ? (
            <div></div>
          ) : (
            <div className="col-6 forma">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="624"
                height="723"
                viewBox="0 0 624 723"
                fill="none"
              >
                <path
                  d="M376.28 79.7576C436.844 124.381 467.962 171.269 516.667 246.205C591.015 360.601 628.194 417.798 623.184 485.388C616.494 575.684 550.12 673.982 464.844 695.44C377.827 717.333 346.987 640.127 230.515 646.228C110.073 652.536 82.3795 738.276 3.56432 720.079C-82.944 700.107 -152.351 573.081 -137.541 473.125C-125.461 391.599 -66.0028 386.445 -47.2344 289.775C-27.8645 190.038 -83.4523 155.83 -53.6072 93.8693C-15.8003 15.3826 109.371 -4.42657 184.942 0.78668C277.771 7.18651 342.017 54.5179 376.279 79.7644L376.28 79.7576Z"
                  fill="url(#paint0_linear_507_178)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_507_178"
                    x1="-127.424"
                    y1="590.028"
                    x2="496.996"
                    y2="209.949"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.14" stop-color="#B267AA" />
                    <stop offset="0.19" stop-color="#BA63A8" />
                    <stop offset="0.29" stop-color="#D25AA3" />
                    <stop offset="0.37" stop-color="#EF509D" />
                    <stop offset="0.46" stop-color="#F05B86" />
                    <stop offset="0.64" stop-color="#F37259" />
                    <stop offset="0.77" stop-color="#F5803E" />
                    <stop offset="0.86" stop-color="#F68634" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          )}
          <div className="col-6 info">
            <h1>
              Què és <em>L'Anòmala?</em>
            </h1>
            <p>
              L’anòmala és un projecte que neix de la mà de set alumnes del Grau
              en Comunicació Audiovisual i Multimèdia de l’Escola Universitària
              ERAM. <br />
            </p>
            <p>
              Es tracta d’una instal·lació interactiva que busca crear una
              experiència gastronòmica on els usuaris es qüestionin la seva
              forma de menjar a partir d’un àpat ple de provocacions. <br />
            </p>
            <p>
              Aquesta instal·lació en format <em>pop-up</em> consistirà en una
              experiència que es podrà viure a l’Escola Universitària de les
              Arts ERAM i posteriorment, es podrà visitar una instal·lació
              explicativa del projecte a El Modern de Girona.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gx-5 ubi">
          <Link to="/Reservation">
            <img src={ERAM} alt="ERAM" className="col-md-6 col-sm-12 ubis" />

            <img
              src={Modern}
              alt="Modern"
              className="col-md-6 col-sm-12 ubis expo"
            />
          </Link>
        </div>
      </div>

      <section>
        <h4 id="objectius">OBJECTIUS</h4>
        <div className="fluid-container objectiusnum">
          {screenWidth < 720 ? (
            <Carousel
              className="carrousel"
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              useKeyboardArrows={true}
              showIndicators={false}
              autoPlay
              interval={2000}
            >
              <div className="obj col-md-4 col-sm-12">
                <div className="row color">
                  <h5 className="col-2 ">1</h5>
                  <p className="col-10">
                    Dissenyar, desenvolupar i crear una instal·lació interactiva
                    única i innovadora de caràcter gastronòmic, utilitzant
                    aquell material i tècniques que les integrants del grup
                    puguin aportar.
                  </p>
                </div>
              </div>
              <div className="obj col-md-4 col-sm-12">
                <div className="row color">
                  <h5 className="col-2">2</h5>
                  <p className="col-10 mt-5">
                    Explorar els hàbits i costums que tenen les persones durant
                    un àpat per descobrir els punts més rellevants a tractar
                    perquè els clients gaudeixin de l’experiència.
                  </p>
                </div>
              </div>
              <div className="obj col-md-4 col-sm-12">
                <div className="row color">
                  <h5 className="col-2 ">3</h5>
                  <p className="col-10 mt-5">
                    Adoptar una mirada crítica,
                    <br /> reflexiva i ètica per observar
                    <br /> l’impacte de la nostra <br />
                    experiència interactiva.
                  </p>
                </div>
              </div>
            </Carousel>
          ) : (
            <div className="row">
              <div className="obj col-md-4 col-sm-12">
                <div className="row color">
                  <h5 className="col-2 mt-5">1</h5>
                  <p className="col-10">
                    Dissenyar, desenvolupar i crear una instal·lació interactiva
                    única i innovadora de caràcter gastronòmic, utilitzant
                    aquell material i tècniques que les integrants del grup
                    puguin aportar.
                  </p>
                </div>
              </div>

              <div className="obj col-md-4 col-sm-12">
                <div className="row color">
                  <h5 className="col-2">2</h5>
                  <p className="col-10 mt-5">
                    Explorar els hàbits i costums que tenen les persones durant
                    un àpat per descobrir els punts més rellevants a tractar
                    perquè els clients gaudeixin de l’experiència.
                  </p>
                </div>
              </div>
              <div className="obj col-md-4 col-sm-12">
                <div className="row color">
                  <h5 className="col-2 ">3</h5>
                  <p className="col-10 mt-5">
                    Adoptar una mirada crítica,
                    <br /> reflexiva i ètica per observar
                    <br /> l’impacte de la nostra <br />
                    experiència interactiva.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}
