import React, { useEffect, useState } from "react";
import Nuria from "../img/nuria.png";
import Carla from "../img/carla.png";
import tate from "../img/tate.png";
import pau from "../img/pau.png";
import marta from "../img/marta.png";
import maribel from "../img/maribel.png";
import laia from "../img/laia.png";
import Nuria2 from "../img/nuria2.png";
import Carla2 from "../img/carla2.png";
import tate2 from "../img/tate2.png";
import pau2 from "../img/pau2.png";
import marta2 from "../img/marta2.png";
import maribel2 from "../img/maribel2.png";
import laia2 from "../img/laia2.png";
import "../menu/Team.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Header from "../Header";
import Footer from "../Footer";

export default function Team() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="team-body">
      <Header title="Team" />
      {screenWidth < 720 ? (
        <Carousel
          className="carrousel"
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          useKeyboardArrows={true}
          showIndicators={false}
          autoPlay
          interval={4000}
        >
          <div className="nuria">
            <img src={Nuria2} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={Carla2} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={tate2} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={pau2} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={marta2} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={maribel2} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={laia2} alt="foto-equip" />
          </div>
        </Carousel>
      ) : (
        <Carousel
          className="carrousel"
          showStatus={false}
          showThumbs={false}
          useKeyboardArrows={true}
          showIndicators={false}
          autoPlay
          interval={4000}
        >
          <div className="nuria">
            <img src={Nuria} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={Carla} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={tate} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={pau} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={marta} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={maribel} alt="foto-equip" />
          </div>
          <div className="nuria">
            <img src={laia} alt="foto-equip" />
          </div>
        </Carousel>
      )}

      <div className="team-footer">
        <Footer />
      </div>
    </div>
  );
}
