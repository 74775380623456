import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./menu/Homepage.css";
import { bubble as Menu } from "react-burger-menu";

import logoheader from "./img/logo-header.svg";
import burger from "../src/img/burger.svg";
import M from "../src/img/M.svg";

export default function Header(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      // Close the menu when scrolling
      if (menuOpen) {
        setMenuOpen(false);
      }
    };
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [menuOpen]);

  return (
    <header className="container-fluid p-0">
      <div className="row">
        {screenWidth < 720 ? (
          <img src={M} alt="logo" className="col-4 logo-header" />
        ) : (
          <img src={logoheader} alt="logo" className="col-4" />
        )}
        <h4 className="col-4">{props.title}</h4>
        <div className="col-4">
          <Menu
            right
            isOpen={menuOpen}
            onStateChange={(state) => setMenuOpen(state.isOpen)}
            customBurgerIcon={<img src={burger} />}
          >
            <a className="menu-item" href="/Homepage">
              Homepage
            </a>
            <a className="menu-item" href="/Project">
              Projecte
            </a>
            <a className="menu-item" href="/Reservation">
              Reserva
            </a>
            <a id="" className="menu-item" href="/Team">
              Equip
            </a>
            <a id="" className="menu-item" href="/Contact">
              Contacte
            </a>
            <a id="" className="menu-item" href="/Questions">
              FAQS
            </a>
          </Menu>
        </div>
      </div>
    </header>
  );
}
