import React from "react";
import Wave from "react-wavify";
import "./Gradient.css";

export default function Gradient(data) {
  return (
    <div className={data.className}>
      <Wave
        className="Wave"
        fill="url(#gradient)"
        paused={false}
        options={{
          height: 10,
          amplitude: 40,
          speed: 0.1,
          points: 9,
        }}
        mask="url(#mask)"
      >
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(-10)">
            <stop offset="10%" stopColor="#B267AA" />
            <stop offset="20%" stopColor="#BA63A8" />
            <stop offset="30%" stopColor="#D25AA3" />
            <stop offset="40%" stopColor="#EF509D" />
            <stop offset="50%" stopColor="#F05B86" />
            <stop offset="60%" stopColor="#F37259" />
            <stop offset="70%" stopColor="#F5803E" />
            <stop offset="80%" stopColor="#F68634" />
            <animateTransform
              attributeName="gradientTransform"
              attributeType="XML"
              type="rotate"
              values="120;10;120"
              keyTimes="0;0.5;1"
              dur="20s"
              repeatCount="indefinite"
              keySplines="0.42 0 0.58 1;0.42 0 0.58 1"
            />
          </linearGradient>
        </defs>
      </Wave>
    </div>
  );
}
